/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "¿Aún te metes en los blogs para leerlos uno a uno? ¿Lees los enlaces que te mandan en Twitter? ¿Cuáles sí? ¿Cuales te olvidas? ¿Cómo gestionas la información que te llega?"), "\n", React.createElement(_components.p, null, "Si aún no conoces la sindicación por RSS léete el siguiente tutorial que nos ofrece ", React.createElement(_components.a, {
    href: "http://www.derrotero.net/"
  }, "DERROTERO.NET"), ":"), "\n", React.createElement(_components.p, null, "Tutorial de lectura masiva de noticias:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/04/03/buscadores-de-oro-diy/"
  }, "DIY")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/04/20/tutorial-de-lectura-masiva-de-noticias-2-el-agregador-o-el-cuerno-de-la-abundancia/"
  }, "El agregador, o el cuerno de la abundancia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/05/01/tutorial-de-lectura-masiva-de-noticias-3-rss-el-flujo-sin-fin/"
  }, "RSS: El flujo sin fin")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/08/27/tutorial-de-lectura-masiva-de-noticias-4-un-ejemplo-basico/"
  }, "Un ejemplo básico")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/09/01/tutorial-de-lectura-masiva-de-noticias-5-rss-vs-redes-sociales/"
  }, "RSS vs redes sociales")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/09/13/tutorial-de-lectura-masiva-de-noticias-6-agregadores-locales-vs-agregadores-online/"
  }, "Agregadores locales vs agregadores online")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2012/11/05/tutorial-de-lectura-masiva-de-noticias-7-programas-de-escritorio-para-leer-noticias/"
  }, "Programas de escritorio para leer noticias")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.derrotero.net/blog/2013/01/06/tutorial-de-lectura-masiva-de-noticias-8-aplicaciones-online-para-leer-noticias-google-reader/"
  }, "Aplicaciones online para leer noticias: Google Reader")), "\n"), "\n", React.createElement(_components.p, null, "La serie me ha parecido muy interesante y muy bien hecha, por eso la comparto. Iré actualizando este índice hasta que termine la serie."), "\n", React.createElement(_components.p, null, "Yo en Ubuntu utilizo Liferea, muy sencillo y en Windows he utilizado Thunderbird junto con el correo, es un poco más complejo pero con más opciones. ¿Cuál eliges tú?"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
